const wrapper = [
  "w-[108px]",
  "h-[27px]",
  "xl:w-[192px]",
  "xl:h-[48px]",
  "flex",
  "items-center",
  "overflow-hidden"
]

const link = [
  "duration-300",
  "flex",
  "flex-col",
  "hover:opacity-80",
  "no-underline",
  "outline-0",
  "cursor-pointer",
  "text-primary-on-color"
]

const logo = [
  "align-middle",
  "mb-[2px]",
  "xl:mb-1"
]

const text = [
  "font-proxima-nova",
  "font-semibold",
  "text-[9px]/[130%]",
  "xl:text-base/[130%]",
  "xl:leading-[18px]",
  "tracking-[.19px]",
  "xl:tracking-[.35px]"
]

export default {
  wrapper,
  link,
  logo,
  text
}
