import { cx } from "cva"
import { socialButtonTelegramStyles } from "shared/lib"

const root = cx([
  "hidden",
  "xl:relative",
  "xl:grid",
  "xl:items-center",
  "xl:justify-center",
  "xl:grid-cols-[410px]",
  "xl:grid-rows-[repeat(3,_max-content)]",
  "xl:gap-[24px]",
  "xl:justify-start",
  "xl:grid-rows-none",
  "xl:grid-cols-[repeat(3,_max-content)]",
  "xl:gap-[65px]",
  "xl:py-9",
  "xl:px-14",
  "xl:bg-bg-main-thirdly",
  "xl:rounded-md"
])

const text = cx([
  "text-[24px]/[31px]",
  "font-bold",
  "text-primary"
])

const number = cx([
  "flex",
  "flex-col"
])

const numberLink = cx([
  "text-[24px]",
  "font-bold",
  "text-primary",
  "whitespace-nowrap",
  "cursor-pointer",
  "no-underline",
  "hover:opacity-70",
  "transition",
  "duration-300"
])

const buttonsLink = cx([
  "!py-[18px]",
  "!px-6",
  "grid",
  "grid-cols-[auto_auto]",
  "gap-3",
  "items-center",
  "leading-4",
  "no-underline",
  "rounded-xl",
  ...socialButtonTelegramStyles
])

const img = cx([
  "absolute",
  "bottom-[-31px]",
  "right-[40px]",
  "w-[159px]",
  "h-[198px]"
])

export default {
  root,
  text,
  number,
  numberLink,
  buttonsLink,
  img
}
